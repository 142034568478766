const defaultLanguages = [
  { label: 'en', value: 'en' },
  { label: 'de', value: 'de' },
  { label: 'es', value: 'es' },
  { label: 'fr', value: 'fr' },
  { label: 'tr', value: 'tr' },
  { label: 'el', value: 'el' },
  { label: 'it', value: 'it' },
  { label: 'pt', value: 'pt' },
  { label: 'ru', value: 'ru' },
  { label: 'ua', value: 'ua' }
]

export default defaultLanguages