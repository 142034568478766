import {useCallback, useEffect} from 'react';
import {useNavigate} from "react-router-dom";

import { useCreateBookmakerMutation } from '@/apis/bookmakers';
import {Pathes} from "@/constants";
import { useToastMessageRequest } from '@/hooks';

import type { IBookmakerFormParams } from '@/types';
import { useGetLanguagesQuery } from "@/apis/languages";

export const useCreateBookmakerPage = () => {
  const [createBookmaker, { isLoading, isSuccess, ...requestParams }] = useCreateBookmakerMutation();
  const { data: languages } = useGetLanguagesQuery({ limit: 10, page: 1 });

  useToastMessageRequest({ isSuccess, ...requestParams });

  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      navigate(Pathes.Bookmakers);
    }
  }, [isSuccess]);

  const onSubmit = useCallback(({ pluses, minuses, services, paymentIcons, mainTips, paymentMethodsImg, ...params }: IBookmakerFormParams) => {
    const filteredPluses = pluses.filter(item => !!item.length);
    const filteredMinuses = minuses.filter(item => !!item.length);
    const filteredServices = services.filter(item => !!item.length);
    const filteredPaymentMethods = paymentIcons?.filter(item => item instanceof Blob);

    createBookmaker({
      pluses: JSON.stringify(filteredPluses),
      minuses: JSON.stringify(filteredMinuses),
      services: JSON.stringify(filteredServices),
      mainTips: JSON.stringify(mainTips),
      paymentMethodsImg: JSON.stringify(paymentMethodsImg),
      paymentIcons: filteredPaymentMethods,
      ...params
    });
  }, []);

  return { isLoading, onSubmit, languages };
};
