import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useEditArticleMutation, useGetArticleByIdQuery } from '@/apis/article';
import { Pathes } from '@/constants';
import { useToastMessageRequest } from '@/hooks';

import type { IArticleFormParams } from '@/types';

export const useEditArticlePage = () => {
  const params = useParams();
  const slug = params.id as string;
  const { data: article } = useGetArticleByIdQuery(slug);
  const [editArticle, { isLoading, isSuccess, ...editArticleParams }] = useEditArticleMutation();
  useToastMessageRequest({ isSuccess, ...editArticleParams });

  const initialValues = useMemo(
    () => ({
      title: article?.title ?? '',
      content: article?.content ?? '',
      translations: article?.articleTranslations ?? [],
      isPublished: article?.isPublished ?? false,
      sportId: article?.sport?.id ?? 0,
      articleSectionId: article?.articleSection.id ?? 0,
      order: article?.order ?? 0,
      seoDescription: article?.seoDescription ?? "",
      lang: article?.language?.name ?? ""
    }),
    [article],
  );

  const onSubmit = useCallback(
    (params: IArticleFormParams) => {
      editArticle({ id: article?.id ?? 0, ...params });
    },
    [article],
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      navigate(Pathes.Articles);
    }
  }, [isSuccess]);

  return { isLoading, initialValues, onSubmit };
};
