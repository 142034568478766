import { Delete, Edit } from '@mui/icons-material';
import { TableCell, TableRow, Box, IconButton } from '@mui/material';
import React from 'react';

import { useTableItem } from '@/hooks';
import { openDeleteBookmakerBonusModal } from '@/redux/bookmaker-bonuses/slice';

import { styles } from './styles';

import type { IBookmakerBonusResponse } from '@/types';

const BookmakerBonusTableItem = (bonus: IBookmakerBonusResponse) => {
  const { onEdit, onDelete } = useTableItem(openDeleteBookmakerBonusModal);
  const { slug, id, title, type, imageUrl, bookmaker } = bonus;

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {id}
      </TableCell>
      <TableCell component="th" scope="row">
        {title}
      </TableCell>
      <TableCell component="th" scope="row">
        <Box component="img" src={imageUrl as string} sx={styles.icon} />
      </TableCell>
      <TableCell component="th" scope="row">
        {type}
      </TableCell>
      <TableCell component="th" scope="row">
        {bookmaker?.name}
      </TableCell>
      <TableCell component="th" scope="row">
        {bookmaker?.language?.name}
      </TableCell>
      <TableCell width={100}>
        <IconButton onClick={onEdit(slug)}>
          <Edit />
        </IconButton>
        <IconButton onClick={onDelete(bonus)}>
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default BookmakerBonusTableItem;
