import {FilterTypes} from "@/types";
import defaultLanguages from "@/constants/lang";

export const headCells = [
  { title: 'Id' },
  { title: 'Title' },
  { title: 'Image' },
  { title: 'Type' },
  { title: 'Bookmaker' },
  { title: 'Lang' },
];
export const filteringFields = [
  null,
  {
    name: 'searchTermByTitle',
    type: FilterTypes.Text
  },
  null,
  null,
  {
    name: 'searchTermByBookmaker',
    type: FilterTypes.Text
  },
  {
    name: 'lang',
    type: FilterTypes.Select,
    values: defaultLanguages,
    defaultValue: '',
  },
  null,
];
