import { useNavigate } from 'react-router-dom';

import { useGetBookmakerBonusesListQuery } from '@/apis/bookmaker-bonuses';
import { usePagination, useTableFilters } from '@/hooks';
import {filteringFields} from "@/views/bookmaker-bonuses/MainBookmakerBonusesPage/constants";

export const useMainBookmakerBonusesPage = () => {
  const navigate = useNavigate();
  const paginationParams = usePagination();
  const { page, rowsPerPage: limit } = paginationParams;
  const [filters, onFiltersChange] = useTableFilters(filteringFields);

  const { data: bonuses, isLoading } = useGetBookmakerBonusesListQuery({
    limit,
    page: page + 1,
    ...filters,
  });

  const count = (bonuses?.meta.totalPages ?? 1) * limit;

  const noData = !bonuses?.items.length;

  const goToCreatePage = () => {
    navigate('create');
  };

  return {
    isLoading,
    noData,
    count,
    bonuses,
    goToCreatePage,
    onFiltersChange,
    ...paginationParams,
  };
};
