import { Box, Button, DialogActions, Grid, MenuItem, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React from 'react';

import { useGetBookmakersListQuery } from '@/apis/bookmakers';
import { DateTimeInput, ImageInput, RichTextEditorInput, SelectInput, TextInput } from '@/components';
import { BonusType } from '@/types';

import { defaultInitialValues } from './constants';
import { bookmakerBonusFormSchema } from './schemas';
import { styles } from './styles';

import type { IBookmakerBonusFormParams, IFormProps } from '@/types';

const BookmakerBonusForm: React.FC<IFormProps<IBookmakerBonusFormParams>> = ({
  initialValues = defaultInitialValues,
  onSubmit,
  isLoading,
}) => {
  const { data: bookmakers } = useGetBookmakersListQuery({ page: 1, limit: 10 });
  return (
    <Formik
      validationSchema={bookmakerBonusFormSchema}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ dirty }) => (
        <Form>
          <Grid container spacing={2} sx={styles.container}>
            <Grid item xs>
              <Field
                type="text"
                name="title"
                autoComplete="title"
                fullWidth
                component={TextInput}
                placeholder="Title"
              />
            </Grid>
            <Grid item xs>
              <Field
                type="text"
                name="link"
                autoComplete="link"
                fullWidth
                component={TextInput}
                placeholder="Link"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={styles.container}>
            <Grid item xs>
            </Grid>
            <Grid item xs>
              <Field
                name="bookmakerId"
                autoComplete="bookmakerId"
                fullWidth
                component={SelectInput}
                placeholder="Bookmaker"
              >
                <MenuItem value="" disabled>
                  <Typography>State</Typography>
                </MenuItem>
                {bookmakers?.items.map(({ name, id }) => (
                  <MenuItem value={id} key={name}>
                    <Typography>{name}</Typography>
                  </MenuItem>
                ))}
              </Field>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={styles.container}>
            <Grid item xs>
              <Field name="createdDate" placeholder="Created Date" component={DateTimeInput} />
            </Grid>
            <Grid item xs>
              <Field name="endDate" placeholder="End Date" component={DateTimeInput} />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={styles.container}>
            <Grid item xs>
              <Field
                name="type"
                autoComplete="type"
                fullWidth
                component={SelectInput}
                placeholder="Type"
              >
                <MenuItem value="" disabled>
                  <Typography>Bookmaker</Typography>
                </MenuItem>
                {Object.values(BonusType).map((type) => (
                  <MenuItem value={type} key={type}>
                    <Typography>{type}</Typography>
                  </MenuItem>
                ))}
              </Field>
            </Grid>
            <Grid item xs>
              <Field
                type="text"
                name="seoDescription"
                autoComplete="seoDescription"
                fullWidth
                component={TextInput}
                placeholder="Seo description"
              />
            </Grid>
          </Grid>
          <Box sx={styles.image}>
            <Field type="file" name="imageUrl" component={ImageInput} placeholder="Image" />
          </Box>
          <Grid container spacing={2} sx={styles.container}>
            <Grid item xs>
              <Field
                type="text"
                name="text"
                autoComplete="text"
                fullWidth
                component={RichTextEditorInput}
                placeholder="Text"
                multiline
                rows={3}
              />
            </Grid>
          </Grid>

          <DialogActions>
            <Button
              variant="contained"
              type="submit"
              disabled={isLoading || !dirty}
              sx={styles.btn}
            >
              Save
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
};

export default BookmakerBonusForm;
