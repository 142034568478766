import { FilterTypes, FilterValues } from '@/types';
import defaultLanguages from "@/constants/lang";

export const headCells = [
  { title: 'Id', width: 200 },
  { title: 'Title', width: 200 },
  { title: 'Sport', width: 200 },
  { title: 'Lang', width: 200 },
  { title: '', width: 100 },
];

export const getFilteringFields = (sports: FilterValues[]) => [
  { name: 'id', type: FilterTypes.Text },
  { name: 'title', type: FilterTypes.Text },
  { name: 'sport', type: FilterTypes.Select, values: sports },
  {
    name: 'lang',
    type: FilterTypes.Select,
    values: defaultLanguages,
  },
  null,
];
