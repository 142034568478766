import { FilterTypes } from '@/types';

import type { FiltersType } from '@/types';
import defaultLanguages from "@/constants/lang";

export const headCells = [
  { title: 'Id', width: 200 },
  { title: 'Name', width: 200 },
  { title: 'Icon', width: 200 },
  { title: 'Bonus', width: 200 },
  { title: 'Rating', width: 200 },
  { title: 'Status', width: 100 },
  { title: 'Lang', width: 100 },
  { title: '', width: 100 },
];

export const filteringFields: FiltersType = [
  { name: 'termById', type: FilterTypes.Text },
  { name: 'termByName', type: FilterTypes.Text },
  null,
  { name: 'termByBonus', type: FilterTypes.Text },
  { name: 'rating', type: FilterTypes.Text },
  {
    name: 'status',
    type: FilterTypes.Select,
    values: [
      { label: 'Active', value: true },
      { label: 'Inactive', value: false },
    ],
    defaultValue: '',
  },
  {
    name: 'lang',
    type: FilterTypes.Select,
    values: defaultLanguages,
    defaultValue: '',
  },
];
