import { Box, Button, DialogActions, Grid, MenuItem, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React, { HTMLAttributes } from 'react';

import {
  AutoCompleteInput,
  CheckboxInput,
  RichTextEditorInput,
  SelectInput,
  TextInput,
} from '@/components';

import { defaultInitialValues } from './constants';
import { newsSchema } from './schema';
import { styles } from './styles';
import { useNewsForm } from './useNewsForm';

import type { IChallengeResponse, IFormProps, INewsFormParams } from '@/types';

const NewsForm: React.FC<IFormProps<INewsFormParams>> = ({
  initialValues = defaultInitialValues,
  onSubmit,
  isLoading,
  languages
}) => {
  const { sports, getOptions } = useNewsForm();
  return (
    <Formik
      enableReinitialize
      validationSchema={newsSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ dirty }) => (
        <Form>
          <Grid container spacing={2} sx={styles.container}>
            <Grid item xs>
              <Field
                name="lang"
                component={SelectInput}
                placeholder="Language"
              >
                <MenuItem value="" disabled>
                  <Typography>Language</Typography>
                </MenuItem>
                {languages?.items.map(({ name }) => (
                  <MenuItem value={name} key={name}>
                    <Typography>{name}</Typography>
                  </MenuItem>
                ))}
              </Field>
            </Grid>
            <Grid item xs>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={styles.container}>
            <Grid item xs>
              <Field
                type="text"
                name="title"
                autoComplete="title"
                fullWidth
                component={TextInput}
                placeholder="Title"
              />
            </Grid>
            <Grid item xs>
              <Field
                name="sportId"
                autoComplete="sportId"
                fullWidth
                component={SelectInput}
                placeholder="Sport"
              >
                <MenuItem value="" disabled>
                  <Typography>Sport</Typography>
                </MenuItem>
                {sports?.items.map(({ name, id }) => (
                  <MenuItem value={id} key={name}>
                    <Typography>{name}</Typography>
                  </MenuItem>
                ))}
              </Field>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={styles.container}>
            <Grid item xs>
              <Field
                type="text"
                name="league"
                autoComplete="league"
                fullWidth
                component={AutoCompleteInput}
                placeholder="League"
                isOptionEqualToValue={(option: IChallengeResponse, value: IChallengeResponse) =>
                  option?.id === value?.id
                }
                getOptionLabel={(option: IChallengeResponse) => option.name}
                getOptions={getOptions}
                renderOption={(
                  props: HTMLAttributes<HTMLLIElement>,
                  option: IChallengeResponse,
                ) => (
                  <Box {...props} component="li">
                    <Box width={30} height={30} component="img" src={option?.logo} />
                    <Typography>{option.name} - {option?.section?.name}</Typography>
                  </Box>
                )}
                textFieldProps={{ placeholder: 'League' }}
              />
            </Grid>
            <Grid item xs>
              <Box mt={3} ml={1}>
                <Field
                  name="isPublished"
                  component={CheckboxInput}
                  label={<Typography variant="h6">Published</Typography>}
                />
              </Box>
            </Grid>
          </Grid>
          <Field
            type="text"
            name="content"
            autoComplete="content"
            fullWidth
            component={RichTextEditorInput}
            placeholder="Content"
            multiline
            rows={3}
          />
          <DialogActions>
            <Button
              variant="contained"
              type="submit"
              disabled={isLoading || !dirty}
              sx={styles.btn}
            >
              Save
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
};

export default NewsForm;
