import { Delete, Edit } from '@mui/icons-material';
import { IconButton, TableCell, TableRow } from '@mui/material';
import React from 'react';

import { useTableItem } from '@/hooks';
import { openDeleteArticleSectionModal } from '@/redux/articles-sections/slice';

import type { IArticleSectionResponse } from '@/types';

const ArticleSectionTableItem: React.FC<IArticleSectionResponse> = (articleSection) => {
  const { id, name, order, language } = articleSection;
  const { onEdit, onDelete } = useTableItem(openDeleteArticleSectionModal);
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {id}
      </TableCell>
      <TableCell component="th" scope="row">
        {name}
      </TableCell>
      <TableCell component="th" scope="row">
        {order}
      </TableCell>
      <TableCell component="th" scope="row">
        {language?.name}
      </TableCell>
      <TableCell component="th" scope="row">
        <IconButton onClick={onEdit(id)}>
          <Edit />
        </IconButton>
        <IconButton onClick={onDelete(articleSection)}>
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default ArticleSectionTableItem;
