export const defaultInitialValues = {
  name: '',
  link: '',
  currency: '',
  bonusLink: '',
  parserName: '',
  textGetBonus: 0,
  feedback_count: 0,
  usersTrust: 0,
  rating: 0,
  paymentMethods: 0,
  withdrawFunds: 0,
  odds: 0,
  bettingLimits: 0,
  bettingCalculationSpeed: 0,
  bonusesIndicator: 0,
  support: 0,
  companyReliability: 0,
  bonus: 0,
  exclusive: false,
  status: false,
  legal: false,
  withoutIdentification: false,
  major: false,
  other: true,
  icon: '',
  pluses: [],
  minuses: [],
  services: [],
  registrationBlockContent: '',
  lineBlockContent: '',
  mobileBlockContent: '',
  supportBlockContent: '',
  cashbackBlockContent: '',
  mainTips: [],
  paymentMethodsImg: [],
  paymentIcons: [],
  lang:'en'
};

export const acceptImageType = {
  'image/*': ['.png'],
};
