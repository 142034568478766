import { Box, Button, DialogActions, Grid, MenuItem, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React from 'react';

import { CheckboxInput, RichTextEditorInput, SelectInput, TextInput } from '@/components';

import { defaultInitialValues } from './constants';
import { articleFormSchema } from './schemas';
import { styles } from './styles';
import { useArticleForm } from './useArticleForm';

import type { IFormProps, IArticleFormParams } from '@/types';
import { useGetLanguagesQuery } from "@/apis/languages";

const ArticleForm: React.FC<IFormProps<IArticleFormParams> & { withTranslation?: boolean }> = ({
  initialValues = defaultInitialValues,
  onSubmit,
  isLoading,
}) => {
  const { data: languages, isLoading: langLoading } = useGetLanguagesQuery({ limit: 10, page: 1 });

  const { sports, sections} =
    useArticleForm();

  if (langLoading) return <div>loading</div>
  return (
    <Formik
      enableReinitialize
      validationSchema={articleFormSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ dirty, values, errors }) => (
        <Form>
          <Grid container spacing={2} sx={styles.container}>
            <Grid item xs>
              <Field
                type="text"
                name="title"
                autoComplete="title"
                fullWidth
                component={TextInput}
                placeholder="Title"
              />
            </Grid>
            <Grid item xs>
              <Field
                name="sportId"
                autoComplete="sportId"
                fullWidth
                component={SelectInput}
                placeholder="Sport"
              >
                <MenuItem value="" disabled>
                  <Typography>Sport</Typography>
                </MenuItem>
                {sports?.items.map(({ name, id }) => (
                  <MenuItem value={id} key={name}>
                    <Typography>{name}</Typography>
                  </MenuItem>
                ))}
              </Field>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={styles.container}>
            <Grid item xs>
              <Field
                name="articleSectionId"
                autoComplete="articleSectionId"
                fullWidth
                component={SelectInput}
                placeholder="Sections"
              >
                <MenuItem value="" disabled>
                  <Typography>Sections</Typography>
                </MenuItem>
                {sections?.items.map(({ name, id }) => (
                  <MenuItem value={id} key={name}>
                    <Typography>{name}</Typography>
                  </MenuItem>
                ))}
              </Field>
            </Grid>
            <Grid item xs>
              <Field
                type="number"
                name="order"
                autoComplete="order"
                fullWidth
                component={TextInput}
                placeholder="Order"
                InputProps={{ inputProps: { min: 0 } }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={styles.container}>
            <Grid item xs>
              <Field
                type="text"
                name="seoDescription"
                autoComplete="seoDescription"
                fullWidth
                component={TextInput}
                placeholder="Seo Description"
              />
            </Grid>
            <Grid item xs>
              <Field
                name="lang"
                component={SelectInput}
                placeholder="Language"
              >
                <MenuItem value="" disabled>
                  <Typography>Language</Typography>
                </MenuItem>
                {languages?.items.map(({ name }) => (
                  <MenuItem value={name} key={name}>
                    <Typography>{name}</Typography>
                  </MenuItem>
                ))}
              </Field>
            </Grid>
          </Grid>
          <Field
            type="text"
            name="content"
            autoComplete="content"
            fullWidth
            component={RichTextEditorInput}
            placeholder="Content"
            multiline
            rows={3}
          />
          <Box my={1}>
            <Field
              name="isPublished"
              component={CheckboxInput}
              label={<Typography variant="h6">Published</Typography>}
            />
          </Box>
          <DialogActions>
            <Button
              variant="contained"
              type="submit"
              disabled={isLoading || !dirty}
              sx={styles.btn}
            >
              Save
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
};

export default ArticleForm;
