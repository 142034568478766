import { Delete, Edit } from '@mui/icons-material';
import { Box, IconButton, TableCell, TableRow } from '@mui/material';
import React from 'react';

import { useTableItem } from '@/hooks';
import { openDeleteBookmakerModal } from '@/redux/bookmakers/slice';

import { styles } from './styles';

import type { IBookmakerResponse } from '@/types';

const BookmakerTableItem: React.FC<IBookmakerResponse> = (bookmaker) => {
  const { language, id, name, icon, rating, status, bonus } = bookmaker;
  const { onDelete, onEdit } = useTableItem(openDeleteBookmakerModal);
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {id}
      </TableCell>
      <TableCell component="th" scope="row">
        {name}
      </TableCell>
      <TableCell component="th" scope="row">
        <Box component="img" src={icon as string} sx={styles.icon} />
      </TableCell>
      <TableCell component="th" scope="row">
        {bonus}
      </TableCell>
      <TableCell component="th" scope="row">
        {rating}
      </TableCell>
      <TableCell component="th" scope="row">
        {status ? 'Active' : 'Inactive'}
      </TableCell>
      <TableCell component="th" scope="row">
        { language?.name }
      </TableCell>
      <TableCell width={100}>
        <IconButton onClick={onEdit(id)}>
          <Edit />
        </IconButton>
        <IconButton onClick={onDelete(bookmaker)}>
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default BookmakerTableItem;
