import { Delete} from '@mui/icons-material';
import { Button, DialogActions, Grid, Typography, Box, Stack, IconButton, MenuItem } from '@mui/material';
import { Field, FieldArray, Form, Formik } from 'formik';
import React from 'react';

import { CheckboxInput, ImageInput, RichTextEditorInput, SelectInput, TextInput } from '@/components';

import FilesDropZoneInput from '../../inputs/FilesDropZoneInput/FilesDropZoneInput';

import { defaultInitialValues, acceptImageType } from './constants';
import { bookmakerFormSchema } from './schemas';
import { styles } from './styles';

import type { IBookmakerFormParams, IFormProps } from '@/types';

const BookmakerForm: React.FC<IFormProps<IBookmakerFormParams>> = ({
  initialValues = defaultInitialValues,
  onSubmit,
  isLoading,
  languages
}) => {
  return (
    <Formik
      enableReinitialize
      validationSchema={bookmakerFormSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ values, dirty }) => (
        <Form>
          <Box sx={styles.icon}>
            <Field
              accept={acceptImageType}
              type="file"
              name="icon"
              component={ImageInput}
              placeholder="Icon"
            />
          </Box>
          <Grid container spacing={2} sx={styles.container}>
            <Grid item xs>
                <Field
                 name="lang"
                  component={SelectInput}
                 placeholder="Language"
                  >
                   <MenuItem value="" disabled>
                      <Typography>Language</Typography>
                   </MenuItem>
                 {languages?.items.map(({ name }) => (
                      <MenuItem value={name} key={name}>
                    <Typography>{name}</Typography>
                  </MenuItem>
                  ))}
               </Field>
            </Grid>
            <Grid item xs>
            </Grid>
           </Grid>
          <Grid container spacing={2} my={2}>
            <Grid item xs>
              <Field
                name="legal"
                component={CheckboxInput}
                label={<Typography variant="h6">Legal</Typography>}
              />
              <Field
                name="withoutIdentification"
                component={CheckboxInput}
                label={<Typography variant="h6">Without identification</Typography>}
              />
              <Field
                name="major"
                component={CheckboxInput}
                label={<Typography variant="h6">Major</Typography>}
              />
              <Field
                name="other"
                component={CheckboxInput}
                label={<Typography variant="h6">Other</Typography>}
              />
            </Grid>
            <Grid item xs>
              <Field
                name="exclusive"
                component={CheckboxInput}
                label={<Typography variant="h6">Exclusive</Typography>}
              />
              <Field
                name="status"
                component={CheckboxInput}
                label={<Typography variant="h6">Status</Typography>}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={styles.container}>
            <Grid item xs>
              <Field
                type="text"
                name="name"
                autoComplete="name"
                fullWidth
                component={TextInput}
                placeholder="Name"
              />
            </Grid>
            <Grid item xs>
              <Field
                type="text"
                name="link"
                autoComplete="link"
                fullWidth
                component={TextInput}
                placeholder="Link"
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={styles.container}>
            <Grid item xs>
              <Field
                type="text"
                name="parserName"
                autoComplete="parserName"
                fullWidth
                component={TextInput}
                placeholder="Parser name"
              />
            </Grid>
            <Grid item xs>
              <Field
                type="text"
                name="bonusLink"
                autoComplete="bonusLink"
                fullWidth
                component={TextInput}
                placeholder="Bonus link"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={styles.container}>
            <Grid item xs>
              <Field
                  type="text"
                  name="currency"
                  autoComplete="currency"
                  fullWidth
                  component={TextInput}
                  placeholder="Currency"
              />
            </Grid>
            <Grid item xs>
              <Field
                  type="number"
                  name="rating"
                  autoComplete="rating"
                  fullWidth
                  component={TextInput}
                  placeholder="Rating"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={styles.container}>
            <Grid item xs>
              <Field
                  type="number"
                  name="textGetBonus"
                  autoComplete="textGetBonus"
                  fullWidth
                  component={TextInput}
                  placeholder="Text get bonus"
              />
            </Grid>
            <Grid item xs>
              <Field
                  type="number"
                  name="feedback_count"
                  autoComplete="feedback_count"
                  fullWidth
                  component={TextInput}
                  placeholder="Feedback count"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={styles.container}>
            <Grid item xs>
              <Field
                type="number"
                name="paymentMethods"
                autoComplete="paymentMethods"
                fullWidth
                component={TextInput}
                placeholder="Payment methods"
              />
            </Grid>
            <Grid item xs>
              <Field
                type="number"
                name="withdrawFunds"
                autoComplete="withdrawFunds"
                fullWidth
                component={TextInput}
                placeholder="Withdraw funds"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={styles.container}>
            <Grid item xs>
              <Field
                type="number"
                name="odds"
                autoComplete="odds"
                fullWidth
                component={TextInput}
                placeholder="Odds"
              />
            </Grid>
            <Grid item xs>
              <Field
                type="number"
                name="bettingLimits"
                autoComplete="bettingLimits"
                fullWidth
                component={TextInput}
                placeholder="Betting limits"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={styles.container}>
            <Grid item xs>
              <Field
                type="number"
                name="bettingCalculationSpeed"
                autoComplete="bettingCalculationSpeed"
                fullWidth
                component={TextInput}
                placeholder="Betting calculation speed"
              />
            </Grid>
            <Grid item xs>
              <Field
                type="number"
                name="support"
                autoComplete="support"
                fullWidth
                component={TextInput}
                placeholder="Support"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={styles.container}>
            <Grid item xs>
              <Field
                type="number"
                name="bonusesIndicator"
                autoComplete="bonusesIndicator"
                fullWidth
                component={TextInput}
                placeholder="Bonuses"
              />
            </Grid>
            <Grid item xs>
              <Field
                type="number"
                name="usersTrust"
                autoComplete="usersTrust"
                fullWidth
                component={TextInput}
                placeholder="Users trust"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={styles.container}>
            <Grid item xs={6}>
              <Field
                type="number"
                name="companyReliability"
                autoComplete="companyReliability"
                fullWidth
                component={TextInput}
                placeholder="Company reliability"
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                type="number"
                name="bonus"
                autoComplete="bonus"
                fullWidth
                component={TextInput}
                placeholder="Bonus"
              />
            </Grid>
          </Grid>
          <Box sx={styles.section}>
            <Field
              type="text"
              name="cashbackBlockContent"
              autoComplete="cashbackBlockContent"
              fullWidth
              component={RichTextEditorInput}
              placeholder="Cashback Block Content"
              multiline
              rows={3}
            />
          </Box>
          <Typography variant="h6" mb={1}>Pros & Cons</Typography>
          <Grid container spacing={2} sx={styles.container}>
            <Grid item xs={12} sm={6}>
              <FieldArray name="pluses">
                {({ insert, remove }) => (
                  <div>
                    {values?.pluses.map((plus, index) => (
                        <Stack key={index} direction="row" spacing={1} paddingTop={1}>
                            <Field
                              name={`pluses.${index}`}
                              type="text"
                              fullWidth
                              component={TextInput}
                              hideEmptyHelperText
                            />
                          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <IconButton aria-label="delete" disabled={values?.pluses.length <= 1} onClick={() => remove(index)}>
                              <Delete />
                            </IconButton>
                          </Box>
                        </Stack>
                      ))}
                    <Button
                      variant="outlined"
                      sx={styles.add_button}
                      onClick={() => insert(values?.pluses.length,'')}
                    >
                      Add Plus
                    </Button>
                  </div>
                )}
              </FieldArray>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FieldArray name="minuses">
                {({ insert, remove }) => (
                  <div>
                    {values?.minuses.map((minus, index) => (
                      <Stack key={index} direction="row" paddingTop={1} spacing={1}>
                        <Field
                          name={`minuses.${index}`}
                          type="text"
                          fullWidth
                          component={TextInput}
                          hideEmptyHelperText
                        />
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <IconButton aria-label="delete" disabled={values?.minuses.length <= 1} onClick={() => remove(index)}>
                            <Delete />
                          </IconButton>
                        </Box>
                      </Stack>
                    ))}
                    <Button
                      variant="outlined"
                      sx={styles.add_button}
                      onClick={() => insert(values?.minuses.length,'')}
                    >
                      Add Minus
                    </Button>
                  </div>
                )}
              </FieldArray>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={styles.container}>
            <Grid item xs={12} sm={6}>
              <Box sx={styles.section}>
                <Typography variant="h6" mb={1}>Features</Typography>
                <FieldArray name="services">
                  {({ insert, remove }) => (
                    <div>
                      {values?.services.map((plus, index) => (
                        <Stack key={index} direction="row" paddingTop={1} spacing={1}>
                          <Field
                            name={`services.${index}`}
                            type="text"
                            fullWidth
                            component={TextInput}
                            hideEmptyHelperText
                          />
                          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <IconButton aria-label="delete" disabled={values?.services.length <= 1} onClick={() => remove(index)}>
                              <Delete />
                            </IconButton>
                          </Box>
                        </Stack>
                      ))}
                      <Button
                        variant="outlined"
                        sx={styles.add_button}
                        onClick={() => insert(values?.services.length,'')}
                      >
                        Add Feature
                      </Button>
                    </div>
                  )}
                </FieldArray>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={styles.section}>
                <Typography variant="h6" mb={1}>Main Tips</Typography>
                  <FieldArray name="mainTips">
                    {({ insert, remove }) => (
                      <div>
                        {values?.mainTips?.map((item, index) => (
                          <Stack key={index} direction="row" paddingTop={1} spacing={1}>
                            <Field
                              name={`mainTips.${index}`}
                              type="text"
                              fullWidth
                              component={TextInput}
                              hideEmptyHelperText
                            />
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <IconButton aria-label="delete" disabled={values?.mainTips.length <= 1} onClick={() => remove(index)}>
                                <Delete />
                              </IconButton>
                            </Box>
                          </Stack>
                        ))}
                        <Button
                          variant="outlined"
                          sx={styles.add_button}
                          onClick={() => insert(values?.mainTips.length,'')}
                        >
                          Add Tip
                        </Button>
                      </div>
                    )}
                  </FieldArray>
              </Box>
            </Grid>
          </Grid>
          <Box sx={styles.section}>
            <Typography variant="h6" mb={1}>Payment Methods</Typography>
            <Field
              type="file"
              name="paymentIcons"
              component={FilesDropZoneInput}
              placeholder="Photo"
              downloadedImages={values?.paymentMethodsImg}
              downloadedName="paymentMethodsImg"
            />
          </Box>
          <Box sx={styles.section}>
            <Field
              type="text"
              name="registrationBlockContent"
              autoComplete="registrationBlockContent"
              fullWidth
              component={RichTextEditorInput}
              placeholder="Sign Up Block Content"
              multiline
              rows={3}
            />
          </Box>
          <Box sx={styles.section}>
            <Field
              type="text"
              name="lineBlockContent"
              autoComplete="lineBlockContent"
              fullWidth
              component={RichTextEditorInput}
              placeholder="Odds Block Content"
              multiline
              rows={3}
            />
          </Box>
          <Box sx={styles.section}>
            <Field
              type="text"
              name="mobileBlockContent"
              autoComplete="mobileBlockContent"
              fullWidth
              component={RichTextEditorInput}
              placeholder="App Block Content"
              multiline
              rows={3}
            />
          </Box>
          <Box sx={styles.section}>
            <Field
              type="text"
              name="supportBlockContent"
              autoComplete="supportBlockContent"
              fullWidth
              component={RichTextEditorInput}
              placeholder="Support Block Content"
              multiline
              rows={3}
            />
          </Box>
          <DialogActions>
            <Button
              variant="contained"
              type="submit"
              disabled={isLoading || !dirty}
              sx={styles.btn}
            >
              Save
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
};

export default BookmakerForm;
