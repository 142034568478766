import { useGetArticleSectionsQuery } from '@/apis/article-section';
import { useGetSportsListQuery } from '@/apis/sports';

import type { FieldArrayRenderProps } from 'formik';

export const useArticleForm = () => {
  const { data: sports } = useGetSportsListQuery({ page: 1, limit: 100 });
  const { data: sections } = useGetArticleSectionsQuery({ page: 1, limit: 100 });

  const addNewTranslation = (arrayHelpers: FieldArrayRenderProps) => () => {
    arrayHelpers.push({
      title: '',
      content: '',
      language: { name: '' },
    });
  };

  const removeTranslation = (arrayHelpers: FieldArrayRenderProps, idx: number) => () => {
    arrayHelpers.remove(idx);
  };

  return { sports, sections, addNewTranslation, removeTranslation };
};
