import { Button, DialogActions, Grid, MenuItem, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React from 'react';

import { SelectInput, TextInput } from '@/components';

import { defaultInitialValues } from './constants';
import { articleSectionFormSchema } from './schemas';
import { styles } from './styles';

import type { IFormProps, IArticleSectionFormParams } from '@/types';
import {useGetLanguagesQuery} from "@/apis/languages";

const ArticleSectionForm: React.FC<
  IFormProps<IArticleSectionFormParams> & { withTranslation?: boolean }
> = ({ initialValues = defaultInitialValues, onSubmit, isLoading, withTranslation = false }) => {
  const { data: languages, isLoading: langLoading } = useGetLanguagesQuery({ limit: 10, page: 1 });

  if (langLoading) return <div>loading</div>
  return (
    <Formik
      enableReinitialize
      validationSchema={articleSectionFormSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ values, dirty, errors }) => {
        return (
          <Form>
            <Grid container spacing={2} sx={styles.container}>
              <Grid item xs>
                <Field
                  type="text"
                  name="name"
                  autoComplete="name"
                  fullWidth
                  component={TextInput}
                  placeholder="Name"
                />
              </Grid>
              <Grid item xs>
                <Field
                  type="number"
                  name="order"
                  autoComplete="order"
                  fullWidth
                  component={TextInput}
                  placeholder="Order"
                  InputProps={{ inputProps: { min: 0 } }}
                />
              </Grid>
              <Grid item xs>
                <Field
                  name="lang"
                  component={SelectInput}
                  placeholder="Language"
                >
                  <MenuItem value="" disabled>
                    <Typography>Language</Typography>
                  </MenuItem>
                  {languages?.items.map(({ name }) => (
                    <MenuItem value={name} key={name}>
                      <Typography>{name}</Typography>
                    </MenuItem>
                  ))}
                </Field>
            </Grid>
            </Grid>
            <DialogActions>
              <Button
                variant="contained"
                type="submit"
                disabled={isLoading || !dirty}
                sx={styles.btn}
              >
                Save
              </Button>
            </DialogActions>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ArticleSectionForm;
